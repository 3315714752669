import { useQuery } from 'react-query'
import client from '../../functions/axios'

export const useGetBalance = () => {
  return useQuery(
    'balance',
    () =>
      client({
        method: 'GET',
        url: `/user/balance`,
      })
        .then((response) => {
          if (response.status === 200) {
            return response.data
          }
        })
        .catch((error) => {
          if (error.response.status === 401 || error.response.status === 403) {
            localStorage.clear()
            window.location = '/login'
          }
          throw error.response?.data?.error
        }),
    {
      refetchInterval: 3000,
      cacheTime: 0,
    }
  )
}

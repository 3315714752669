import { createTheme, experimental_sx as sx } from '@mui/material/styles'
import '@mui/lab/themeAugmentation'

const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#1E659A',
    },
    secondary: {
      main: '#FF6B58',
    },
    background: {
      default: '#f5f5f5',
    },
  },
  typography: {
    fontFamily: 'Poppins',
  },
  components: {
    MuiChip: {
      styleOverrides: {
        root: sx({
          borderRadius: 1,
        }),
        label: {
          fontSize: '0.8rem',
          textTransform: 'capitalize',
        },
      },
    },
  },
})

export default theme

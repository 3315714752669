import { lazy, Suspense } from 'react'
import { Toaster } from 'react-hot-toast'
import { Route, Routes } from 'react-router-dom'
import { useFavicon } from 'react-haiku'
import Loading from './components/Loading'
import PrivateRoutes from './components/PrivateRoutes'
import Layout from './layout'

const PageNotFound = lazy(() => import('./pages/PageNotFound'))
const Dashboard = lazy(() => import('./pages/Dashboard'))
const Users = lazy(() => import('./pages/Users'))
const Biller = lazy(() => import('./pages/Biller'))
const Bill = lazy(() => import('./pages/Bill'))
const RechargeRequest = lazy(() => import('./pages/RechargeRequest'))
const Messages = lazy(() => import('./pages/Messages'))
const Banks = lazy(() => import('./pages/Banks'))
const Reports = lazy(() => import('./pages/Reports'))
const WalletLedger = lazy(() => import('./pages/WalletLedger'))
const Login = lazy(() => import('./pages/Login'))

const Root = () => {
  const { setFavicon } = useFavicon()

  setFavicon(`${process.env.PUBLIC_URL}/images/favicon.png`)

  return (
    <Suspense fallback={<Loading showImage />}>
      <Toaster position="top-right" toastOptions={{}} />
      <Routes>
        <Route element={<PrivateRoutes />}>
          <Route path="/" element={<Layout />}>
            <Route index element={<Dashboard />} />
            <Route path="/users" element={<Users />} />
            <Route path="/biller" element={<Biller />} />
            <Route path="/bill" element={<Bill />} />
            <Route path="/recharge-request" element={<RechargeRequest />} />
            <Route path="/messages" element={<Messages />} />
            <Route path="/walletledger" element={<WalletLedger />} />
            <Route path="/reports" element={<Reports />} />
            <Route path="/banks" element={<Banks />} />
          </Route>
        </Route>
        <Route
          path="/login"
          element={
            <Suspense fallback={<Loading showImage />}>
              <Login />
            </Suspense>
          }
        />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </Suspense>
  )
}

export default Root

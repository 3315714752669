import toast from 'react-hot-toast'
import { useMutation, useQueryClient } from 'react-query'
import client from '../../functions/axios'

export const useAddTopup = () => {
  const queryClient = useQueryClient()

  return useMutation(
    ({ amount }) => {
      return client({
        method: 'PUT',
        url: '/user/admintopup',
        data: {
          amount: amount,
        },
      })
        .then((response) => {
          return response.data
        })
        .catch((error) => {
          throw error
        })
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('balance')
      },
      onError: (error, variables, context) => {
        toast.error(error.response.data.error)
      },
    }
  )
}

import { format } from 'date-fns'

export const formateDateTime = (date) => {
  return format(new Date(date), 'do MMMM, yyyy hh:mm:ss aaa')
}

export const formateDate = (date) => {
  return format(new Date(date), 'do MMMM, yyyy')
}

export const formateTime = (time) => {
  return format(new Date(time), 'hh:mm:ss aaa')
}

import axios from 'axios'

const client = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  json: true,
  headers: {
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  },
})

axios.interceptors.request.use(
  function (config) {
    return config
  },
  function (error) {
    if (401 === error.response.status || 403 === error.response.status) {
      localStorage.clear()
      window.location = '/login'
    }
    return Promise.reject(error)
  }
)

axios.interceptors.response.use(
  function (response) {
    return response
  },
  function (error) {
    if (401 === error.response.status || 403 === error.response.status) {
      localStorage.clear()
      window.location = '/login'
    }
    return Promise.reject(error)
  }
)

export default client

import {
  AccountBalanceWalletRounded,
  CloseRounded,
  DataObjectRounded,
  EmailRounded,
  EventNoteRounded,
  LocalPhoneRounded,
  LocationOnRounded,
  PersonRounded,
  UpdateRounded,
  PercentRounded,
  RouterRounded,
} from '@mui/icons-material'
import {
  Avatar,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Skeleton,
  Stack,
  TextField,
  Tooltip,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { useState } from 'react'
import DialogTransition from '../../../components/DialogTransition'
import { formateDateTime } from '../../../functions/formateDate'
import { useGenerateApiKey } from '../../../hooks/users/useGenerateApiKey'
import { useGetProfile } from '../../../hooks/users/useGetProfile'
import WhitelistIPModal from './WhitelistIPModal'

const UserProfileModal = ({ open, handleClose }) => {
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))

  const { data, isLoading } = useGetProfile()

  const generateApi = useGenerateApiKey(false)

  const [openWhiteListIPModal, setOpenWhiteListIPModal] = useState(false)
  const handleOpenWhiteListIPModal = () => setOpenWhiteListIPModal(true)
  const handleCloseWhiteListIPModal = () => setOpenWhiteListIPModal(false)

  return (
    <Dialog
      fullWidth
      keepMounted
      TransitionComponent={DialogTransition}
      fullScreen={fullScreen}
      scroll="paper"
      maxWidth="sm"
      open={open}
      onClose={(_, reason) => {
        if (reason !== 'backdropClick') {
          handleClose()
        }
      }}
    >
      <DialogTitle
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        component={Stack}
      >
        <span>Profile details</span>
        <IconButton size="small" color="error" onClick={handleClose}>
          <CloseRounded fontSize="inherit" />
        </IconButton>
      </DialogTitle>
      <DialogContent
        dividers
        sx={{
          padding: 0,
        }}
      >
        <List disablePadding component="div" role="group">
          <ListSubheader>Personal details</ListSubheader>
          <Details
            isLoading={isLoading}
            primary="Full name"
            secondary={data?.name}
          />
          <Details
            isLoading={isLoading}
            primary="Username"
            secondary={data?.username}
            icon={<PersonRounded />}
          />
          <Details
            isLoading={isLoading}
            primary="Email address"
            secondary={data?.email}
            icon={<EmailRounded />}
          />
          <Details
            isLoading={isLoading}
            primary="Phone number"
            secondary={data?.mobile}
            icon={<LocalPhoneRounded />}
          />
          <Details
            isLoading={isLoading}
            primary="Created at"
            secondary={isLoading ? '' : formateDateTime(data?.createdAt)}
            icon={<EventNoteRounded />}
          />
          <Details
            isLoading={isLoading}
            primary="Updated at"
            secondary={isLoading ? '' : formateDateTime(data?.updatedAt)}
            icon={<UpdateRounded />}
          />
          <ListSubheader>Account details</ListSubheader>
          <Details
            isLoading={isLoading}
            primary="Available balance"
            secondary={data?.balance}
            icon={<AccountBalanceWalletRounded />}
          />
          <Details
            isLoading={isLoading}
            primary="Commission"
            secondary={`${data?.comPer} %`}
            icon={<PercentRounded />}
          />
          <Details
            isLoading={isLoading}
            primary="API key"
            secondary={data?.apiKey}
            icon={<DataObjectRounded />}
            secondaryAction={
              <Tooltip
                arrow
                disableFocusListener
                placement="top"
                title="Generate New API key"
              >
                <span>
                  <IconButton
                    disabled={generateApi.isLoading || data?.isAdmin}
                    onClick={() => {
                      generateApi.mutate()
                    }}
                    aria-label="Generate API key"
                  >
                    {generateApi.isLoading ? (
                      <CircularProgress size={24} />
                    ) : (
                      <DataObjectRounded />
                    )}
                  </IconButton>
                </span>
              </Tooltip>
            }
          />
          <Details
            isLoading={isLoading}
            primary="API access IP address"
            secondary={data?.apiKeyIp}
            icon={<LocationOnRounded />}
            secondaryAction={
              <Tooltip
                arrow
                disableFocusListener
                placement="top"
                title="Whitelist IP address"
              >
                <span>
                  <IconButton
                    disabled={data?.isAdmin}
                    onClick={handleOpenWhiteListIPModal}
                  >
                    <RouterRounded />
                  </IconButton>
                </span>
              </Tooltip>
            }
          />
          <Details
            isLoading={isLoading}
            primary="Last login IP address"
            secondary={data?.lastLoginIp}
            icon={<LocationOnRounded />}
          />
        </List>
        <WhitelistIPModal
          open={openWhiteListIPModal}
          handleClose={handleCloseWhiteListIPModal}
        />
      </DialogContent>
    </Dialog>
  )
}

export default UserProfileModal

const Details = ({ isLoading, primary, secondary, icon, secondaryAction }) => {
  return (
    <ListItem
      divider
      secondaryAction={
        isLoading ? (
          <Skeleton
            animation="wave"
            variant="circular"
            width={40}
            height={40}
          />
        ) : (
          secondaryAction
        )
      }
    >
      <ListItemIcon>
        {isLoading ? (
          <Skeleton
            animation="wave"
            variant="circular"
            width={40}
            height={40}
          />
        ) : (
          <Avatar>{icon ? icon : primary?.charAt(0)?.toUpperCase()}</Avatar>
        )}
      </ListItemIcon>
      <ListItemText
        primary={
          isLoading ? <Skeleton animation="wave" width="40%" /> : primary
        }
        secondary={
          isLoading ? <Skeleton animation="wave" width="80%" /> : secondary
        }
      />
    </ListItem>
  )
}

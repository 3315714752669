import { MenuRounded } from '@mui/icons-material'
import {
  AppBar as MuiAppBar,
  Box,
  IconButton,
  Toolbar,
  Typography,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import AppBarAvatar from './AppBarAvatar'
import ViewBalance from './ViewBalance'

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    // width: `calc(100% - ${drawerWidth}px)`,
    // marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}))

const LayoutAppBar = ({ open, handleDrawerOpen }) => {
  return (
    <AppBar position="fixed" open={open}>
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          edge="start"
          sx={{
            mr: 2,
            // ...(open && { display: 'none' })
          }}
        >
          <MenuRounded />
        </IconButton>
        <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
          Nirved Services
        </Typography>

        <Box sx={{ display: 'flex' }} alignItems="center">
          <ViewBalance />
          <AppBarAvatar />
        </Box>
      </Toolbar>
    </AppBar>
  )
}

export default LayoutAppBar

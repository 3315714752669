import { Box, CircularProgress, Stack } from '@mui/material'
import { useTitle } from 'react-haiku'

const Loading = ({ showImage }) => {
  useTitle('Nirved Services - Loading...')
  return (
    <Box
      display="flex"
      minHeight="100vh"
      alignItems="center"
      justifyContent="center"
      overflow="hidden"
      s
    >
      <Stack alignItems="center" spacing={2}>
        {showImage && (
          <img
            src={`${process.env.PUBLIC_URL}/images/logo.png`}
            width="160"
            alt="Nirved Services"
          />
        )}
        <CircularProgress size={30} />
      </Stack>
    </Box>
  )
}

export default Loading

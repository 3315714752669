import { Box, styled } from '@mui/material'
import { useState } from 'react'
import { Outlet } from 'react-router-dom'
import LayoutAppBar from './components/LayoutAppBar'
import { LayoutDrawer } from './components/LayoutDrawer'
import { DrawerHeader } from './DrawerHeader'

const Layout = () => {
  const [open, setOpen] = useState(false)

  const handleDrawerOpen = () => setOpen(true)
  const handleDrawerClose = () => setOpen(false)

  return (
    <Box sx={{ display: 'flex' }}>
      <LayoutAppBar open={open} handleDrawerOpen={handleDrawerOpen} />
      <LayoutDrawer open={open} handleDrawerClose={handleDrawerClose} />
      <Main open={open}>
        <DrawerHeader />
        <Outlet />
      </Main>
    </Box>
  )
}

export default Layout

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    backgroundColor: theme.palette.grey[100],
    overflow: 'hidden',
    overflowY: 'auto',
    height: '100vh',
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    // marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
)

import { useEffect, useState } from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import Loading from '../components/Loading'
import client from '../functions/axios'

const PrivateRoutes = () => {
  const [isAuth, setIsAuth] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    client({
      method: 'GET',
      url: '/User/auth',
    })
      .then((response) => {
        if (response.status === 200) {
          setIsAuth(true)
        } else {
          setIsAuth(false)
          localStorage.clear()
        }
        setIsLoading(false)
      })
      .catch((error) => {
        setIsAuth(false)
        localStorage.clear()
        setIsLoading(false)
      })

    return () => {
      setIsLoading(true)
      setIsAuth(false)
    }
  }, [])

  return isLoading ? (
    <Loading showImage />
  ) : isAuth ? (
    <Outlet />
  ) : (
    <Navigate to="/login" />
  )
}

export default PrivateRoutes

import {
  CloseRounded,
  VisibilityOffRounded,
  VisibilityRounded,
} from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  Stack,
  Tooltip,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { Field, Form, Formik } from 'formik'
import { TextField } from 'formik-mui'
import { useState } from 'react'
import toast from 'react-hot-toast'
import * as Yup from 'yup'
import DialogTransition from '../../../components/DialogTransition'
import client from '../../../functions/axios'

const UserChangePasswordModal = ({ open, handleClose }) => {
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const [showNewPassword, setShowNewPassword] = useState(false)
  const [showOldPassword, setShowOldPassword] = useState(false)

  const initialValues = {
    oldPassword: '',
    newPassword: '',
  }

  const validationSchema = Yup.object().shape({
    oldPassword: Yup.string().required().min(5).max(15).label('New password'),
    newPassword: Yup.string().required().min(5).max(15).label('Old password'),
  })

  const onSubmit = async (values, actions) => {
    return await client({
      method: 'PUT',
      url: `/user/password`,
      data: values,
    })
      .then((response) => {
        if (response.status === 200) {
          actions.resetForm()
          toast.success(`password updated successfully`)
          handleClose()
        }
      })
      .catch((error) => {
        toast.error(error.response.data.error)
      })
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ isValid, dirty, isSubmitting }) => (
        <Dialog
          fullWidth
          TransitionComponent={DialogTransition}
          keepMounted={false}
          fullScreen={fullScreen}
          scroll="paper"
          maxWidth="sm"
          open={open}
          PaperProps={{
            component: Form,
          }}
          onClose={(_, reason) => {
            if (reason !== 'backdropClick') {
              handleClose()
            }
          }}
        >
          <DialogTitle
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            component={Stack}
          >
            <span>Change Password</span>
            <IconButton size="small" color="error" onClick={handleClose}>
              <CloseRounded fontSize="inherit" />
            </IconButton>
          </DialogTitle>
          <DialogContent dividers>
            <Stack spacing={2}>
              <Field
                fullWidth
                autoComplete="off"
                size="small"
                component={TextField}
                label="Old password"
                name="oldPassword"
                inputProps={{
                  type: showOldPassword ? 'text' : 'password',
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip
                        arrow
                        placement="top"
                        title={`${showOldPassword ? 'Hide' : 'Show'} Password`}
                      >
                        <IconButton
                          size="small"
                          onClick={() => setShowOldPassword(!showOldPassword)}
                        >
                          {!showOldPassword ? (
                            <VisibilityRounded fontSize="inherit" />
                          ) : (
                            <VisibilityOffRounded fontSize="inherit" />
                          )}
                        </IconButton>
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
              />

              <Field
                fullWidth
                autoComplete="off"
                size="small"
                component={TextField}
                label="New password"
                name="newPassword"
                inputProps={{
                  type: showNewPassword ? 'text' : 'password',
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip
                        arrow
                        placement="top"
                        title={`${showNewPassword ? 'Hide' : 'Show'} Password`}
                      >
                        <IconButton
                          size="small"
                          onClick={() => setShowNewPassword(!showNewPassword)}
                        >
                          {!showNewPassword ? (
                            <VisibilityRounded fontSize="inherit" />
                          ) : (
                            <VisibilityOffRounded fontSize="inherit" />
                          )}
                        </IconButton>
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
              />
            </Stack>
          </DialogContent>
          <DialogActions>
            <LoadingButton
              type="submit"
              disabled={!(dirty && isValid) || isSubmitting}
              loading={isSubmitting}
            >
              Change password
            </LoadingButton>
          </DialogActions>
        </Dialog>
      )}
    </Formik>
  )
}

export default UserChangePasswordModal

import toast from 'react-hot-toast'
import { useMutation } from 'react-query'
import { useNavigate } from 'react-router-dom'
import client from '../../functions/axios'

export const useLogout = () => {
  const navigate = useNavigate()

  return useMutation(
    () =>
      client({
        method: 'GET',
        url: `/user/logout`,
      })
        .then((res) => {
          return res.data
        })
        .catch((error) => {
          throw error
        }),
    {
      onSuccess: (data, variables, context) => {
        toast.success('You have been successfully logged out!')
        localStorage.clear()
        navigate('/login')
      },
      onError: (error, variables, context) => {
        toast.error(error.response.data.error)
      },
    }
  )
}

import {
  IconButton,
  Avatar,
  Menu,
  MenuItem,
  ListItemIcon,
  Divider,
} from '@mui/material'
import {
  LockRounded,
  AttachMoneyRounded,
  LogoutRounded,
} from '@mui/icons-material'
import { useState, Fragment } from 'react'
import { useLocalStorage } from 'react-haiku'
import AddTopupBalanceModal from './AddTopupBalanceModal'
import { useLogout } from '../../hooks/users/useLogout'
import AccessControl from '../../components/AccessControl'
import UserChangePasswordModal from '../../pages/Users/components/UserChangePasswordModal'
import UserProfileModal from '../../pages/Users/components/UserProfileModal'

const AppBarAvatar = () => {
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const handleClick = (event) => setAnchorEl(event.currentTarget)
  const handleClose = () => setAnchorEl(null)

  const [openTopupModal, setOpenTopupModal] = useState(false)
  const handleOpenTopupModal = () => setOpenTopupModal(true)
  const handleCloseTopupModal = () => setOpenTopupModal(false)

  const [openPasswordChangeModal, setOpenPasswordChangeModal] = useState(false)
  const handleOpenPasswordChangeModal = () => setOpenPasswordChangeModal(true)
  const handleClosePasswordChangeModal = () => setOpenPasswordChangeModal(false)

  const [openProfileModal, setOpenProfileModal] = useState(false)
  const handleOpenProfileModal = () => setOpenProfileModal(true)
  const handleCloseProfileModal = () => setOpenProfileModal(false)

  const [userData] = useLocalStorage('data')

  const logout = useLogout()

  return (
    <Fragment>
      <IconButton
        onClick={handleClick}
        size="small"
        sx={{ ml: 2 }}
        aria-controls={open ? 'account-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
      >
        <Avatar sx={{ width: 32, height: 32 }}>
          {userData.username.charAt(0).toUpperCase()}
        </Avatar>
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem disabled>Hello {userData.username}</MenuItem>
        <Divider />
        <MenuItem onClick={handleOpenProfileModal}>
          <ListItemIcon>
            <Avatar />
          </ListItemIcon>
          Profile
        </MenuItem>
        <Divider />
        <AccessControl>
          <MenuItem onClick={handleOpenTopupModal}>
            <ListItemIcon>
              <AttachMoneyRounded fontSize="small" />
            </ListItemIcon>
            Topup Balance
          </MenuItem>
        </AccessControl>

        <MenuItem onClick={handleOpenPasswordChangeModal}>
          <ListItemIcon>
            <LockRounded fontSize="small" />
          </ListItemIcon>
          Change password
        </MenuItem>
        <MenuItem onClick={() => logout.mutate()} disabled={logout.isLoading}>
          <ListItemIcon>
            <LogoutRounded fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
      <AddTopupBalanceModal
        open={openTopupModal}
        handleClose={handleCloseTopupModal}
      />
      <UserChangePasswordModal
        open={openPasswordChangeModal}
        handleClose={handleClosePasswordChangeModal}
      />
      <UserProfileModal
        open={openProfileModal}
        handleClose={handleCloseProfileModal}
      />
    </Fragment>
  )
}

export default AppBarAvatar

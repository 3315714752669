import toast from 'react-hot-toast'
import { useMutation, useQueryClient } from 'react-query'
import client from '../../functions/axios'

export const useGenerateApiKey = (user = true) => {
  const queryClient = useQueryClient()

  return useMutation(
    (values) => {
      return client({
        method: 'POST',
        url: user
          ? `/User/generateApiKey/${values.userId}`
          : `/User/generateApiKey`,
      })
        .then((response) => {
          return response.data
        })
        .catch((error) => {
          throw error
        })
    },
    {
      onSuccess: (data, variables, context) => {
        navigator.clipboard.writeText(data.apiKey).then(() => {
          toast.success('API Key Copied to clipboard')
        })
        if (user === false) {
          queryClient.setQueryData('profile', (prev) => {
            return {
              ...prev,
              apiKey: data.apiKey,
            }
          })
        }
      },
      onError: (error, _newPost, rollback) => {
        toast.error(error.response.data.error)
        if (rollback) rollback()
      },
    }
  )
}

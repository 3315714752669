import {
  AccountBalanceRounded,
  AccountBalanceWalletRounded,
  AssessmentRounded,
  CloseRounded,
  DashboardRounded,
  DescriptionRounded,
  ForumRounded,
  GroupRounded,
  LogoutRounded,
  ReceiptLongRounded,
  RequestPageRounded,
} from '@mui/icons-material'
import {
  CircularProgress,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material'
import { red } from '@mui/material/colors'
import { styled } from '@mui/material/styles'
import jwt_decode from 'jwt-decode'
import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { drawerWidth } from '../../constant'
import { useLogout } from '../../hooks/users/useLogout'

export const LayoutDrawer = ({ open, handleDrawerClose }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const logout = useLogout()
  const decoded = jwt_decode(localStorage.getItem('token'))

  const MENU = [
    {
      title: 'Dashboard',
      link: '/',
      isAdmin: 'True',
      icon: <DashboardRounded />,
    },
    {
      title: 'Users',
      link: '/users',
      isAdmin: decoded?.admin,
      icon: <GroupRounded />,
    },
    {
      title: 'Biller',
      link: '/biller',
      isAdmin: 'True',
      icon: <DescriptionRounded />,
    },
    {
      title: 'Bill',
      link: '/bill',
      isAdmin: 'True',
      icon: <ReceiptLongRounded />,
    },
    {
      title: 'Recharge Request',
      link: '/recharge-request',
      isAdmin: 'True',
      icon: <RequestPageRounded />,
    },
    {
      title: 'Messages',
      link: '/messages',
      isAdmin: decoded?.admin,
      icon: <ForumRounded />,
    },
    {
      title: 'Wallet Ledger',
      link: '/walletledger',
      isAdmin: 'True',
      icon: <AccountBalanceWalletRounded />,
    },
    {
      title: 'Reports',
      link: '/reports',
      isAdmin: 'True',
      icon: <AssessmentRounded />,
    },
    {
      title: 'Banks',
      link: '/banks',
      isAdmin: decoded?.admin,
      icon: <AccountBalanceRounded />,
    },
  ]

  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
        },
      }}
      anchor="left"
      open={open}
      onClose={handleDrawerClose}
    >
      <DrawerHeader>
        <img
          src={`${process.env.PUBLIC_URL}/images/logo.png`}
          alt="Nirved Services"
          height={45}
        />
        <IconButton color="error" onClick={handleDrawerClose}>
          <CloseRounded />
        </IconButton>
      </DrawerHeader>
      <Divider />
      <List disablePadding>
        {MENU.map(
          (menu, index) =>
            menu.isAdmin === 'True' && (
              <ListItem
                divider
                key={index}
                disablePadding
                onClick={() => navigate(menu.link)}
              >
                <ListItemButton
                  selected={location.pathname === menu.link ? true : false}
                >
                  <ListItemIcon
                    sx={{
                      color:
                        location.pathname === menu.link ? '#1e659a' : '#a6a7aa',
                    }}
                  >
                    {menu?.icon}
                  </ListItemIcon>
                  <ListItemText
                    primary={menu.title}
                    primaryTypographyProps={{
                      fontSize: 14,
                      fontWeight: 'medium',
                      color: '#1e659a',
                    }}
                  />
                </ListItemButton>
              </ListItem>
            )
        )}
        <ListItem
          divider
          disablePadding
          onClick={() => logout.mutate()}
          sx={{
            bgcolor: red[50],
            color: red[800],
          }}
        >
          <ListItemButton disabled={logout.isLoading}>
            <ListItemIcon
              sx={{
                color: red[800],
              }}
            >
              {logout.isLoading ? (
                <CircularProgress size={24} color="error" />
              ) : (
                <LogoutRounded />
              )}
            </ListItemIcon>
            <ListItemText
              primary={logout.isLoading ? 'Logging out...' : 'Log out'}
              primaryTypographyProps={{
                fontSize: 14,
                fontWeight: 'medium',
                color: '',
              }}
            />
          </ListItemButton>
        </ListItem>
      </List>
    </Drawer>
  )
}

export const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: 'space-between',
}))

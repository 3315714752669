import { useQuery } from 'react-query'
import client from '../../functions/axios'

export const useGetProfile = () =>
  useQuery('profile', () =>
    client({
      method: 'GET',
      url: `/user`,
    })
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        throw error.response.data.error
      })
  )

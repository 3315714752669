import { CloseRounded } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { Field, Form, Formik } from 'formik'
import { TextField } from 'formik-mui'
import * as Yup from 'yup'
import ipRegex from 'ip-regex'
import DialogTransition from '../../../components/DialogTransition'
import { useWhiteListIP } from '../../../hooks/users/useWhiteListIP'

const WhitelistIPModal = ({ open, handleClose, userId }) => {
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))

  const whiteListIP = useWhiteListIP()

  const initialValues = {
    ...(userId && { userId: userId }),
    ip: '',
  }

  const validationSchema = Yup.object().shape({
    ip: Yup.string()
      .required()
      .matches(ipRegex(), 'Please provide a valid IP address')
      .label('IP Address'),
  })

  const onSubmit = async (values, actions) => {
    actions.setSubmitting(true)
    return whiteListIP.mutateAsync(values, {
      onSuccess: (data) => {
        actions.resetForm()
        actions.setSubmitting(false)
        handleClose()
      },
      onError: (error) => {
        actions.setSubmitting(false)
      },
    })
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ isValid, dirty, isSubmitting }) => (
        <Dialog
          fullWidth
          keepMounted={false}
          TransitionComponent={DialogTransition}
          fullScreen={fullScreen}
          scroll="paper"
          maxWidth="sm"
          open={open}
          PaperProps={{
            component: Form,
          }}
          onClose={(_, reason) => {
            if (reason !== 'backdropClick') {
              handleClose()
            }
          }}
        >
          <DialogTitle
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            component={Stack}
          >
            <span>Add IP to Whitelist</span>
            <IconButton size="small" color="error" onClick={handleClose}>
              <CloseRounded fontSize="inherit" />
            </IconButton>
          </DialogTitle>
          <DialogContent dividers>
            <Field
              fullWidth
              size="small"
              autoComplete="off"
              component={TextField}
              label="IP address"
              name="ip"
            />
          </DialogContent>
          <DialogActions>
            <LoadingButton
              type="submit"
              loading={isSubmitting}
              disabled={!(dirty && isValid) || isSubmitting}
            >
              Whitelist IP
            </LoadingButton>
          </DialogActions>
        </Dialog>
      )}
    </Formik>
  )
}

export default WhitelistIPModal

import toast from 'react-hot-toast'
import { useMutation } from 'react-query'
import client from '../../functions/axios'

export const useWhiteListIP = () => {
  return useMutation(
    (values) => {
      return client({
        method: 'PUT',
        url: values.userId
          ? `/user/whitelistip/${values.userId}?ip=${values.ip}`
          : `/user/whitelistip?ip=${values.ip}`,
      })
        .then((response) => {
          return response.data
        })
        .catch((error) => {
          throw error
        })
    },
    {
      onSuccess: (data, variables, context) => {
        toast.success('IP whitelisted')
      },
      onError: (error, variables, context) => {
        toast.error(error.response.data.error)
      },
    }
  )
}

import { CloseRounded } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { Field, Form, Formik } from 'formik'
import { TextField } from 'formik-mui'
import * as Yup from 'yup'
import 'yup-phone'
import DialogTransition from '../../components/DialogTransition'
import { useAddTopup } from '../../hooks/admin/useAddTopup'

const AddTopupBalanceModal = ({ open, handleClose }) => {
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))

  const { isLoading, mutate } = useAddTopup()

  const initialValues = {
    amount: '',
  }

  const validationSchema = Yup.object().shape({
    amount: Yup.number().required().min(100).max(1000000).label('Amount'),
  })

  const onSubmit = async (values, actions) =>
    await mutate(values, {
      onSuccess: () => {
        actions.resetForm()
        handleClose()
      },
    })

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ isValid, dirty, isSubmitting, values }) => (
        <Dialog
          fullWidth
          keepMounted={false}
          TransitionComponent={DialogTransition}
          fullScreen={fullScreen}
          scroll="paper"
          maxWidth="sm"
          open={open}
          PaperProps={{
            component: Form,
          }}
          onClose={(_, reason) => {
            if (reason !== 'backdropClick') {
              handleClose()
            }
          }}
        >
          <DialogTitle
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            component={Stack}
          >
            <span>Topup balance</span>
            <IconButton size="small" color="error" onClick={handleClose}>
              <CloseRounded fontSize="inherit" />
            </IconButton>
          </DialogTitle>
          <DialogContent dividers>
            <Stack spacing={1.5}>
              <Field
                fullWidth
                size="small"
                autoComplete="off"
                component={TextField}
                label="Amount"
                name="amount"
                inputProps={{
                  type: 'number',
                }}
              />
            </Stack>
          </DialogContent>
          <DialogActions>
            <LoadingButton
              type="submit"
              disabled={!(dirty && isValid) || isSubmitting || isLoading}
              loading={isSubmitting || isLoading}
            >
              Add topup
            </LoadingButton>
          </DialogActions>
        </Dialog>
      )}
    </Formik>
  )
}

export default AddTopupBalanceModal
